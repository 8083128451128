import React from 'react'

const BannerLanding = (props) => (
    <section id="banner" className={props.bannerStyle}>
        <div className="inner">
            <header className="major">
                {(props.headerImage && props.headerImage.length > 0
                    ?
                    <img src={props.headerImage} alt=""/>
                    :
                    <h1>{props.headerText}</h1>
                )}
            </header>
            <div className="content">
                <p>{props.descriptionText}</p>
            </div>
        </div>
    </section>
)

export default BannerLanding
