import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { isLoggedIn, logout } from "../services/auth"

const Menu = (props) => (
    <nav id="menu">
        <div className="inner">
            <ul className="links">
                <li><Link onClick={props.onToggleMenu} to="/">Home</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/tree-fruit">Tree Fruit</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/table-grapes">Table Grapes</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/pomegranates">Pomegranates</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/citrus">Citrus</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/mighties">Mighties™</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/#company">What Makes Us Different</Link></li>
            </ul>
            <ul className="actions vertical">
                <li><Link onClick={props.onToggleMenu} to="/#contact" className="button special fit">Contact Us</Link></li>
                <li><a href="https://moonlightcompanies.applytojob.com/apply/" target="_blank" className="button fit">Careers / Empleo</a></li>
                <li><Link onClick={logout} to="/login" className="button special fit">Log Out</Link></li>
            </ul>
        </div>
        <a className="close" onClick={props.onToggleMenu} href="javascript:;">Close</a>
    </nav>
)

Menu.propTypes = {
    onToggleMenu: PropTypes.func
}

export default Menu
