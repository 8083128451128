import React from "react"
import { navigate } from "gatsby"
import Helmet from 'react-helmet'
import { handleLogin, isLoggedIn } from "../services/auth"

class Login extends React.Component {
    state = {
        username: ``,
        password: ``,
        error: false,
    }

    handleUpdate = event => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    handleSubmit = event => {
        event.preventDefault()
        var response = handleLogin(this.state);
        if (response === false) {
            this.setState({
                error: true,
            })
        } else {
            navigate('/')
        }
    }

    render() {
        if (isLoggedIn()) {
            navigate(`/`)
        }

        return (
            <>
                <Helmet>
                    <title>Login - Moonlight</title>
                    <meta name="description" content="Login" />
                </Helmet>
                <div id="main">
                    <section id="one">
                        <div className="inner">
                            <header className="major">
                                <h1>Log in</h1>
                            </header>
                            <form
                                method="post"
                                onSubmit={event => {
                                    this.handleSubmit(event)
                                }}
                            >
                                <label>
                                    Username
                                    <input type="text" name="username" onChange={this.handleUpdate} />
                                </label>
                                <label>
                                    Password
                                    <input
                                        type="password"
                                        name="password"
                                        onChange={this.handleUpdate}
                                    />
                                </label>
                                <input type="submit" value="Log In" />
                            </form>
                            <p>Having Problems?  Email <a href="mailto:tyler@specksoftware.com">tyler@specksoftware.com</a></p>
                            {this.state.error === true ? (
                                <p style={{ color: `red` }}>Incorrect Username or Password</p>
                            ) : (
                                    <p></p>
                                )
                            }
                        </div>
                    </section>
                </div>

            </>
        )
    }
}

export default Login