export const isBrowser = () => typeof window !== "undefined"
const userKey = "specksoftwareUser";

export const getUser = () =>
  isBrowser() && window.localStorage.getItem(userKey)
    ? JSON.parse(window.localStorage.getItem(userKey))
    : {}

const setUser = user =>
  window.localStorage.setItem(userKey, JSON.stringify(user))

export const handleLogin = ({ username, password }) => {
  if (username === `moonlight-4309` && password === `just-a-demo-5098`) {
    return setUser({
      username: `moonlight-4309`,
      name: `Demo User`,
    })
  }

  return false
}

export const isLoggedIn = () => {
  const user = getUser()

  return !!user.username
}

export const logout = () => {
  setUser({})
}